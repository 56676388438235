<script>
import Faq_modal from "@/components/faq-modal.vue";
import json from "@/db/faq.json";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
    components: { Header, Footer, Faq_modal },
    setup() {
        return {
            json,
        };
    },
};
</script>

<template>
    <div class="container container_black">
        <Header />
        <section class="header_bd">
            <h1 class="ta_center">Frequently Asked Questions</h1>
        </section>
        <main class="child_wrap main_ccf">
            <div class="faq_container flex flex_col">
                <div class="faq_rivet">
                    <h2>Our Values</h2>
                    <div class="modal_wrap">
                        <Faq_modal v-for="data in json.values" :key="data.content" :data="data" />
                    </div>
                </div>
                <div class="faq_rivet">
                    <h2>Our Plans</h2>
                    <div class="modal_wrap">
                        <Faq_modal v-for="data in json.plans" :key="data.content" :data="data" />
                    </div>
                </div>
                <div class="faq_rivet">
                    <h2>Api Keys</h2>
                    <div class="modal_wrap flex flex_col">
                        <Faq_modal v-for="data in json.apikey" :key="data.content" :data="data" />
                    </div>
                </div>
                <div class="faq_rivet">
                    <h2>Helpful Information for New Developers</h2>
                    <div class="modal_wrap">
                        <Faq_modal v-for="data in json.developers" :key="data.content" :data="data" />
                    </div>
                </div>
                <div class="faq_rivet">
                    <h2>Dashboard</h2>
                    <div class="modal_wrap">
                        <Faq_modal v-for="data in json.dashboard" :key="data.content" :data="data" />
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<style lang="scss" scoped>
h1 {
    max-width: 90%;
    margin: 0 auto;
}
h2 {
    color: $grey;
    text-transform: capitalize;
}
.faq_container {
    padding: 8rem 0px;
    gap: 7rem 0px;
    @include media("<=tablet") {
        gap: 5rem 0px;
        padding: 5rem 0px;
    }
    .faq_rivet {
        @include flex(space-between, flex-start, none);
        h2 {
            padding: 0px;
            width: 35%;
            @include media("<=tablet") {
                width: 85%;
            }
        }
        .modal_wrap {
            width: 62%;
            @include flex_col(4rem 0);
            @include media("<=tablet") {
                width: 100%;
                @include flex_col(3rem 0);
            }
        }
        @include media("<=tablet") {
            @include flex_col(4rem 0px);
        }
    }
}
</style>
