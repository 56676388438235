import store from "../../store";

const checkAuth = () => {
    return new Promise((resolve, reject) => {
        fetch("/api/account")
            .then((result) => {
                if (result.ok) {
                    store.state.authenticated = true;
                    resolve();
                } else {
                    store.state.authenticated = false;
                    reject();
                }
            })
            .catch((err) => {
                reject();
                return err;
            });
    });
};

export default new checkAuth();
