<template>
    <div class="child_wrap flex ftr">
        <ul class="links">
            <li>
                <router-link :to="{ name: 'privacy policy' }"> Privacy Policy </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'terms' }"> Terms Of Use </router-link>
            </li>
            <li>
                <a href="mailto:support@rivet.cloud">Email Support</a>
            </li>
        </ul>
        <div class="rivet_socials flex icons">
            <div class="git icon">
                <a target="_blank" href="https://github.com/openrelayxyz/ethercattle-deployment">
                    <img src="@/assets/github.svg" alt="" />
                </a>
            </div>
            <div class="mdm icon">
                <a target="_blank" href="https://medium.com/rivet-magazine">
                    <img src="@/assets/medium.svg" alt="" />
                </a>
            </div>
            <div class="twtr icon">
                <a target="_blank" href="https://twitter.com/R_I_V_E_T">
                    <img src="@/assets/twitter.svg" alt="" />
                </a>
            </div>
            <div class="discd icon">
                <a target="_blank" href="https://discord.gg/zANTsgdGeH">
                    <img src="@/assets/discord.svg" alt="" />
                </a>
            </div>
        </div>
        <div class="copyright">
            <p>
                Copyright &copy;
                <a href="https://openrelay.xyz">OpenRelay </a> 2022 All Rights Reserved.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style lang="scss" scoped>
.ftr {
    @include flex(space-between, center, none);
    padding: 15px 0px;
    @include media("<=desktop") {
        @include flex_col(2rem 0);
        align-items: flex-start;
    }
    @include media("<=phone-tab") {
        gap: 1em 0px;
        padding: 2.3rem 0px;
    }
    .links {
        width: 30%;
        font-size: 0.9em;
        @include flex(space-between, center, none);
        @include media("<=desktop") {
            width: 100%;
            @include flex(left, center, 0 1.2em);
        }
        li {
            list-style-type: none;
        }
    }
    .icons {
        width: 20%;
        @include flex(space-between, center, none);
        @include media("<=desktop") {
            width: 100%;
            @include flex(left, center, 0 1em);
        }
        .icon {
            width: 40px;
            img {
                width: 100%;
            }
        }
    }
    .copyright {
        p {
            font-size: 0.9em;
        }
    }
    a {
        color: $grey;
        text-decoration: none;
    }
}
</style>
