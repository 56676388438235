<template>
    <div class="cont" :class="{ light: isLight }">
        <header class="child_wrap flex">
            <div class="logo">
                <router-link to="/">
                    <div class="img">
                        <img v-if="!isLight" src="@/assets/rivet.svg" alt="" />
                        <img v-else src="https://res.cloudinary.com/ferventdev/image/upload/v1642420872/openRelay.xyz/rivet_svg_p2983k.svg" alt="" />
                    </div>
                </router-link>
            </div>
            <div @click="openNavBarAndAnimateSpan" class="menu_btn">
                <div class="buttons" :class="{ open: $store.state.toggleMenu }">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <nav>
                <ul class="flex">
                    <li>
                        <router-link to="/"> Home </router-link>
                    </li>
                    <li>
                        <a href="https://rivet.cloud/docs">Docs</a>
                    </li>
                    <li>
                        <router-link :to="{ name: 'FAQ' }">FAQs</router-link>
                    </li>
                </ul>
            </nav>
            <div class="btn_grp" :class="button">
                <router-link v-if="!$store.state.authenticated" :to="{ name: 'login' }">
                    <button>Login</button>
                </router-link>
                <router-link v-else :to="{ name: 'overview' }">
                    <button>Dashboard</button>
                </router-link>
            </div>
        </header>
    </div>
</template>

<script>
import checkAuth from "../scripts/auth/checkAuth";
import store from "../store";
export default {
    props: ["button", "isLight"],
    mounted() {
        checkAuth
            .then(() => {
                console.log("Logged in");
            })
            .catch(() => {
                console.error("Not Authorized");
            });
    },
    methods: {
        openNavBarAndAnimateSpan() {
            store.state.toggleMenu = !store.state.toggleMenu;
        },
    },
};
</script>
<style lang="scss" scoped>
.light {
    color: $offblack !important;
    background: white !important;
}
.cont {
    background: #020204;
    color: $fadegrey;
    position: sticky;
    top: 0;
    z-index: 400;
    header {
        .logo {
            width: 120px;
            @include media("<=phone-tab") {
                width: 95px;
            }
            .img {
                width: 100%;
                img {
                    width: 100%;
                    @extend %nodrag;
                }
            }
        }
        .btn_grp {
            @include media("<=tablet") {
                display: none;
            }
        }
        .menu_btn {
            display: none;
            @include media("<=tablet") {
                display: block;
            }
            .buttons {
                span {
                    width: 30px;
                    background: rgb(255, 255, 255);
                    height: 1px;
                    margin: 6px 0px;
                    transition: 0.6s ease-in-out;
                    display: block;
                }
            }
            .buttons.open {
                span {
                    background: white;
                    &:nth-child(1) {
                        transform: rotateZ(35deg) translateY(6px);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        transform: rotateZ(-35deg) translateY(-10px);
                    }
                }
            }
        }
        nav {
            width: 30%;
            @include media("<=tablet") {
                display: none;
            }
            ul {
                justify-content: space-evenly;
                li {
                    list-style: none;
                }
            }
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
}
</style>
