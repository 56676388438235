<template>
    <div class="wrap_item">
        <div class="header_wrap">
            <h3>{{ data.header }}</h3>
            <div @click="toggle" class="toggleView content_btn">
                <span></span>
                <span :class="{ swoosh: clicked }"></span>
            </div>
        </div>
        <div ref="accordion_txt" :class="{ active: clicked }" class="accordion_txt">
            <p v-html="data.content"></p>
        </div>
        <div class="bottom_line"></div>
    </div>
</template>

<script>
export default {
    name: "Faq_modal",
    props: ["data"],
    data() {
        return {
            clicked: false,
        };
    },
    methods: {
        toggle() {
            this.clicked = !this.clicked;
        },
    },
};
</script>

<style lang="scss" scoped>
.swoosh {
    opacity: 0;
}
.wrap_item {
    @include flex_col(2.5rem);
    .header_wrap {
        @include flex(space-between, center, 0 1rem);
        cursor: pointer;
        h3 {
            font-size: 1.5em;
        }
        .toggleView {
            padding: 1rem 0;
            span {
                height: 0.05rem;
                display: block;
                width: 2rem;
                background: white;
                transition: 0.3s ease-in-out;
                @include media("<=phone-tab") {
                    width: 1.5em;
                }
                &:nth-child(2) {
                    transform: rotateZ(90deg);
                }
            }
        }
    }
    .accordion_txt {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
        p {
            width: 90%;
            line-height: 1.9em;
            @include media("<=phone-tab") {
                width: 100%;
            }
        }
    }
    .active {
        max-height: 30rem !important;
    }
    .bottom_line {
        background: rgba(48, 47, 47, 0.699);
        width: 100%;
        height: 1px;
    }
}
</style>
